import React, { useEffect, useState } from 'react';
import "./portal.scss";
import { useTranslation } from "react-i18next";
import { SzBox, SzAlert, SzIcon, SzTypographie, SzSpinner, SzButton } from '@suezenv/react-theme-components';
import PortalManager from "../../manager/portal/PortalManager";
import { ReactComponent as CircleIcon } from "../map/svg/circle.svg";
import { config } from '../../config';
import { ApiUrls } from '../../constants';
import Request from '../../services/Request';

const PortalComponent = () => {
    
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<'portail' | 'chantier'>('portail');
    const [ interventions, setInterventions ] = useState([])
    const [count, setCount] = useState({ ongoing: 0, planned: 0 }); // Stores the intervention counter
    const [loading, setLoading] = useState(true); // Indicates if loading is in progress
    const [error, setError] = useState(false); // Manages request errors

   
    useEffect(() => {
        if (activeTab === 'portail') {

            Request.get(ApiUrls.GET_INTERVENTIONS, false, true)
            .then(res => {
                const { count, interventions } = res.data
                
                setInterventions(interventions)
                setLoading(false)
                setCount(count)
            })
            .catch(error => {
                setLoading(false)
                setError(true)
            })
        }
    }, [activeTab])
    

    // Display intervention counters
    const renderCounts = () => {
        if (loading) return <div className="text-center"><SzSpinner /></div>;
        if (error) {
            return (
                <SzAlert transparent={true} variant='warning'>
                    <div className='py-4 alert-text'>
                        <SzTypographie variant='body' weight='bold'>{t('intervention_error')}</SzTypographie>
                    </div>
                </SzAlert>
            );
        } else {
            return (
                <ul className="legend-inline">
                    <li className="status-ongoing">
                        <SzTypographie variant="caption" weight="medium">
                            <CircleIcon className="icon intervention-icon__ongoing mr-2" />
                            {t('intervention_ongoing', { count: count.ongoing })}
                        </SzTypographie>
                    </li>
                    <li className="status-planned">
                        <SzTypographie variant="caption" weight="medium">
                            <CircleIcon className="icon intervention-icon__planned mr-2" />
                            {t('intervention_planned', { count: count.planned })}
                        </SzTypographie>
                    </li>
                </ul>
            );
        }
    };

    return (
        <section className="h-100">
            <SzBox className="box--no-padding cat-portal h-100" tag="div">
                {/* Navigation buttons */}
                <div className="tab-buttons d-flex justify-content-center my-4">
                    <SzButton
                        variant={activeTab === 'portail' ? 'primary' : 'secondary'}
                        onClick={() => setActiveTab('portail')}
                    >
                        {t('portail')}
                    </SzButton>
                    <SzButton
                        variant={activeTab === 'chantier' ? 'primary' : 'secondary'}
                        onClick={() => {
                            if (activeTab === 'chantier') {
                                setActiveTab('portail');
                                setTimeout(() => {
                                    setActiveTab('chantier');
                                }, 0.0001);   

                            } else {
                                setActiveTab('chantier');
                            }
                        }}
                        className="ml-3"
                    >
                    {t('chantier')}
                </SzButton>
                </div>

                {activeTab === 'portail' ? (
                    // "Portail" section
                    <div key="portail">
                        <header className="m-5">
                            <SzTypographie variant="h2" weight="bold" className="d-flex align-items-center justify-content-between mb-5">
                                {t('portal')}
                                <a href="#" rel="noopener noreferrer">
                                <span onClick={() => setActiveTab('chantier')} style={{ cursor: 'pointer' }}>
                                    <SzIcon variant="bold" icon="arrow-right" />
                                </span>

                                </a>
                            </SzTypographie>
                            {renderCounts()}
                        </header>
                        <article>
                            <div className="row">
                                <div className="col map-container">
                                    {/* Display interventions only if they are loaded */}
                                    {loading ? (
                                        <SzSpinner />
                                    ) : interventions?.length ? (
                                        <PortalManager interventions={interventions}/>
                                    ) : (
                                        <p className="text-center">Aucune intervention à afficher</p>
                                    )}

                                </div>
                            </div>
                        </article>
                    </div>
                ) : (
                    // "Chantier" section
                    <div key="chantier">
                        <SzTypographie variant="h2" weight="bold" className="d-flex align-items-center justify-content-between mb-5">
                            {t('chantier_title')}
                        </SzTypographie>
                        <iframe
                            title="Cartographie Toulouse Métropole"
                            src="https://data.toulouse-metropole.fr/explore/dataset/chantiers-en-cours/map/?location=13,43.63732,1.43689&basemap=jawg.streets"
                            width="100%"
                            height="500px"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                )}
            </SzBox>
        </section>
    );
};

export default PortalComponent;
